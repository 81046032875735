import * as React from "react";

// Import Swiper React components
import { FreeMode } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { isDesktop } from "react-device-detect";
import { StaticImage } from "gatsby-plugin-image";

const ReviewSliderAPAD = () => {
  return (
    <div className="max-w-6xl py-16 mx-auto ">
      <Swiper
        modules={[FreeMode]}
        freeMode={true}
        grabCursor={true}
        spaceBetween={30}
        centeredSlides={true}
        className={`relative ${
          isDesktop ? " !overflow-visible" : " overflow-hidden"
        }`}
      >
        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px] ml-8 md:ml-0">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/apad/gps-fleet-kudrat.webp"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “Recovered over 40 missing cars from our fleet.”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Cheong Mun Yuen
            </h5>
            <p className="text-sm leading-none ">
              Fleet Manager, Kudrat Malaysia
            </p>
            <p className="mt-6 text-sm">
              KATSANA® GPS Fleet has been a great addition to our fleet of
              vehicles.
              <br />
              <br />
              Apart from enabling us to identify risky drivers, we have been
              able to <strong>recover over 40 missing vehicles</strong> from
              hire-purchase debtors; all with
              <strong>100% recovery rate</strong> of missing vehicles.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/apad/gps-fleet-wcm.webp"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “Helped us find RM1.2 million of stolen asset.”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Ms Siah
            </h5>
            <p className="text-sm leading-none ">
              Manager, WCM Machinery Sdn Bhd
            </p>
            <p className="mt-6 text-sm">
              KATSANA® GPS Tracking & Fleet Management System allowed WCM
              Machinery to{" "}
              <strong>
                recover a stolen lorry carrying multi-million dollar electric
                generator
              </strong>{" "}
              in under an hour.
              <br />
              <br />
              Whats more, we have helped the police to recover another truck
              that was also stolen & <strong>nabbed the thieves</strong> in the
              vicinity.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/apad/gps-fleet-aerodyne.webp"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “The best choice for GPS Fleet Management System”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              Kamarul Muhamed
            </h5>
            <p className="text-sm leading-none ">Group CEO, Aerodyne Group</p>
            <p className="mt-6 text-sm">
              <strong>Aerodyne operates in over 35 countries.</strong>
              <br />
              <br />
              Our ground operation relies on KATSANA GPS Fleet tracking system
              to coordinate and ensure smooth delivery of services to our
              Fortune 500 clients.
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/apad/gps-fleet-mstime.webp"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “KATSANA support is outstanding and accomodating to our
                  needs.”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              MS Time Cranes & Hoists
            </h5>
            <p className="text-sm leading-none ">Operation Manager</p>
            <p className="mt-6 text-sm">
              <strong>We are extremely pleased</strong> with KATSANA® GPS Fleet
              tracking services. The support has been very helpful and
              understanding in arranging installation for our nationwide fleets.
              <br />
              <br />
              We operate a large number of heavy vehicles, and KATSANA® has been
              instrumental in helping us gain{" "}
              <strong>meaningful insight into our ground operations.</strong>
            </p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="w-72 px-4 py-6 bg-white rounded md:px-6 md:py-8 md:w-[360px]">
            <div className="flex items-center min-h-[110px] ">
              <div className="relative ">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/apad/gps-fleet-klhop.webp"
                  objectFit="cover"
                  className="inset-0 w-12 h-12 rounded-full md:w-20 md:h-20"
                />
              </div>
              <div className="relative ml-4 text-center">
                <StaticImage
                  alt="katsana"
                  src="../../static/images/graphics/5stars.jpg"
                  objectFit="cover"
                  className="inset-0 w-[180px] h-[49px]"
                />
                <p className="text-sm text-bluetext">
                  “Easy-to-use and friend GPS Fleet Management System”
                </p>
              </div>
            </div>
            <h5 className="my-2 mt-4 text-xl font-semibold leading-none">
              KL Hop On Hop Off
            </h5>
            <p className="text-sm leading-none ">Fleet Manager</p>
            <p className="mt-6 text-sm">
              KATSANA® provides us with the solution for us to track and manage
              our fleet. It is easy to use – with a glance we are able to view
              all of our bus locations across Kuala Lumpur and Penang.
              <br />
              <br />
              The Support team provides friendly assistance before, during and
              after installation, making it the{" "}
              <strong>best fleet tracking solution in Malaysia.</strong>
            </p>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default ReviewSliderAPAD;
