import * as React from "react";
import LazyLoad from "react-lazyload";
import FreeMode from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "../styles/swiper.css";

// import Swiper core and required modules
import SwiperCore, { Pagination } from "swiper/core";

// install Swiper modules
SwiperCore.use([Pagination]);

const CustomerSlider = ({ datas }) => {
  return (
    <Swiper
      modules={[FreeMode]}
      freeMode={true}
      grabCursor={true}
      slidesPerView={1}
      spaceBetween={0}
      centeredSlides={true}
      pagination={{
        clickable: true,
      }}
      className="!overflow-visible"
    >
      {datas.nodes.map((data, index) => {
        return (
          <SwiperSlide key={index}>
            <div className="flex flex-col justify-start w-56 p-4 bg-white rounded-lg md:p-6 md:p-8 md:w-72">
              <div className="mt-4 md:mt-6">
                <LazyLoad>
                  <div
                    className="bg-center bg-no-repeat bg-contain h-14 "
                    style={{
                        backgroundImage: `url(${data.customer.logo.sourceUrl})`,
                    }}
                  />
                </LazyLoad>
              </div>
              <p
                className="my-6 text-xs leading-5 md:text-sm md:leading-6 md:my-12"
                dangerouslySetInnerHTML={{ __html: data.content }}
              ></p>
              {data.customer.url !== null && (
                <a
                  href={data.customer.url}
                  className="inline-flex justify-center px-5 py-3 font-medium text-white bg-blue-800 rounded-full md:flex"
                >
                  Read Case Study
                </a>
              )}
            </div>
          </SwiperSlide>
        );
      })}
    </Swiper>
  );
};

export default CustomerSlider;
