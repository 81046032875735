import React, { useState, useEffect } from "react";
import { graphql, useStaticQuery } from "gatsby";

const Customer = () => {
  const [filter, setFilter] = useState("Customers");
  const [projects, setProjects] = useState([]);
  const { CustomersData }  = useStaticQuery(graphql`
    query allCustomers {
      CustomersData: allWpCustomer {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
    }
  `);  

  useEffect(() => {
    setProjects([]);
    const filtered = CustomersData.nodes.map((p) => ({
      ...p,
      filtered: p.categories.nodes.map((item) => item.name).includes(filter),
    }));

    setProjects(filtered);
  }, [filter]);

  return (
    <>
      <div className="flex flex-col px-8 md:flex-row md:px-0">
        <div className="w-full max-w-[240px] cursor-pointer customers__labels text-blue-900">
          <h5 className="mb-4 font-medium uppercase">Filter by industries</h5>
          <div className="flex flex-col items-start space-y-3">
            <button
              onClick={() => setFilter("Customers")}
              className={filter === "Customers" ? "font-medium text-blue-600" : ""}
            >
              All Industries
            </button>
            <button
              onClick={() => setFilter("Agriculture")}
              className={
                filter === "Agriculture" ? "font-medium text-blue-600" : ""
              }
            >
              Agriculture
            </button>
            <button
              onClick={() => setFilter("Construction & Property")}
              className={
                filter === "Construction & Property" ? "font-medium text-blue-600" : ""
              }
            >
              Construction & Property
            </button>
            <button
              onClick={() => setFilter("General Services")}
              className={
                filter === "General Services" ? "font-medium text-blue-600" : ""
              }
            >
              General Services
            </button>
            <button
              onClick={() => setFilter("Government & Local Bodies")}
              className={
                filter === "Government & Local Bodies" ? "font-medium text-blue-600" : ""
              }
            >
              Government & Local Bodies
            </button>
            <button
              onClick={() => setFilter("Logistics & Transportation")}
              className={
                filter === "Logistics & Transportation" ? "font-medium text-blue-600" : ""
              }
            >
              Logistics & Transportation
            </button>
            <button
              onClick={() => setFilter("Sea Ports & Terminals")}
              className={
                filter === "Sea Ports & Terminals" ? "font-medium text-blue-600" : ""
              }
            >
              Sea Ports & Terminals
            </button>
            <button
              onClick={() => setFilter("Public Transport")}
              className={
                filter === "Public Transport" ? "font-medium text-blue-600" : ""
              }
            >
              Public Transport
            </button>
            <button
              onClick={() => setFilter("Public Works & Utilities")}
              className={
                filter === "Public Works & Utilities" ? "font-medium text-blue-600" : ""
              }
            >
              Public Works & Utilities
            </button>
            <button
              onClick={() => setFilter("Rental & Leasing")}
              className={filter === "Rental & Leasing" ? "font-medium text-blue-600" : ""}
            >
              Rental & Leasing
            </button>
          </div>
        </div>
        <div className="grid grid-cols-6 gap-6 customers__container transform translate-y-[-14vh] z-30">
          {projects.map((item, i) =>
            item.filtered === true ? (
              <div
                className="relative flex flex-col justify-start w-56 col-span-2 p-6 bg-white rounded-lg md:p-8 md:w-72"
                key={i}
              >
                <div className="mt-6">
                  <div
                    className="bg-center bg-no-repeat bg-contain h-14 "
                    style={{
                      backgroundImage: `url(${item.customer.logo.sourceUrl})`,
                    }}
                  />
                </div>
                <p
                  className="my-6 text-sm leading-6 md:my-12"
                  dangerouslySetInnerHTML={{ __html: item.content }}
                ></p>

                {item.customer.url !== null && (
                  <a
                    href={item.customer.url}
                    className="inline-flex px-8 py-3 font-medium text-white bg-blue-800 rounded-full md:flex md:justify-center md:text-center md:mx-auto "
                  >
                    Read Case Study
                  </a>
                )}
              </div>
            ) : (
              ""
            )
          )}
        </div>
      </div>
    </>
  );
};

export default Customer;
