import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticImage } from "gatsby-plugin-image";
import LazyLoad from "react-lazyload";
import CountUp from "react-countup";
import Layout from "../components/layout";
import { ParallaxProvider, Parallax } from "react-scroll-parallax";
import Customer from "../components/customer";
import Cta from "../components/cta";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import * as Styles from "../styles/success-stories.module.css";
import CustomerAccordion from "../components/customeraccordion";
import ReviewSliderAPAD from "../components/reviewsliderapad";

const CustomerPage = () => {
  const breakpoints = useBreakpoint();
  return (
    <ParallaxProvider>
      <Layout pageTitle="Home Page">
        {/* MetaTag */}
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <title>
            Customers - KATSANA® Integrated Fleet Management & Industrial
            Operation Solutions
          </title>
          <meta
            name="description"
            content="Thousands of companies, from small enterprises to Fortune 500 companies rely on KATSANA solutions to transform and modernize their operations with actionable data direct from the ground, enabling time-sensitive decisions to be made."
          />
          <meta
            name="robots"
            content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"
          />
          <link
            rel="canonical"
            href="https://www.katsana.com/success-stories/"
          />

          {/* Open Graph */}
          <meta property="og:locale" content="en_US" />
          <meta property="og:type" content="website" />
          <meta
            property="og:url"
            content="https://www.katsana.com/success-stories/"
          />
          <meta
            property="og:title"
            content="Customers - KATSANA® Integrated Fleet Management & Industrial Operation Solutions"
          />
          <meta
            property="og:description"
            content="Thousands of companies, from small enterprises to Fortune 500 companies rely on KATSANA solutions to transform and modernize their operations with actionable data direct from the ground, enabling time-sensitive decisions to be made."
          />
          <meta
            property="og:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
          <meta
            property="og:image:alt"
            content="KATSANA® Fleet Management & Integrated Operation Solutions"
          />
          <meta property="og:image:width" content="1280" />
          <meta property="og:image:height" content="640" />

          {/* Twitter Card */}
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@katsanagps" />
          <meta name="twitter:creator" content="@katsanagps" />

          {/* image must be 2:1 max 5mb */}
          <meta
            name="twitter:image"
            content="https://assets.katsana.com/og-images/twt_ogimage_large.jpg"
          />
        </Helmet>
        {/* Hero Area */}
        <div className="relative p-8 pb-20 overflow-hidden md:p-0 md:pt-32 md:pb-48">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(213,56%,19%,1)] m-[-1px] after:h-[4vh] md:after:h-[6vh] z-20 "
              }
            ></div>
          </div>

          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(0,0%,0%,0.2)] m-[-1px] after:h-[4vh] md:after:h-[12vh] z-10 "
              }
            ></div>
          </div>

          <StaticImage
            alt="katsana"
            src="../../static/images/photos/customers-hero.jpg"
            objectFit="cover"
            className="!absolute inset-0"
          />

          <div className="relative z-10 max-w-6xl mx-auto md:text-center">
            <p className="max-w-[200px] pt-24 md:pt-12 md:mx-auto text-sm text-bluegray md:text-base md:max-w-none">
              Fleet Management and Industrial Operation Solutions
            </p>
            <h1 className="max-w-5xl pt-4 mx-auto text-4xl font-bold tracking-wide text-white md:pt-2 md:leading-tight md:mt-0 md:text-6xl">
              Trusted by companies across South East Asia
            </h1>
            <p className="max-w-2xl mt-12 text-sm leading-6 tracking-wide md:mx-auto md:text-center md:mt-8 text-bluegray md:leading-9 md:text-xl">
              Thousands of companies, from small enterprises to Fortune 500
              companies rely on KATSANA solutions to transform and modernize
              their operations with actionable data direct from the ground,
              enabling time-sensitive decisions to be made.
            </p>

            <div className="justify-start max-w-xl mx-auto mt-12 md:flex md:items-center md:justify-between md:mt-20">
              <a
                href="https://apps.katsana.com/get-quotation/"
                className="inline px-5 py-2 font-semibold text-white rounded-full md:flex md:self-start md:px-6 md:py-3 md:text-base leading-0 bg-primary"
              >
                Request Consultation
              </a>
              <a
                href="tel:+60377334474"
                className="block mt-4 font-semibold text-white md:flex text-xss md:text-sm md:text-primary md:ml-4 md:mt-0"
                target="_blank"
                rel="noopener noreferrer"
              >
                or call us +603 7733 4474 (Kuala Lumpur, GMT +8)
              </a>
            </div>
          </div>
        </div>

        <div className="relative bg-gradient-to-b from-[hsla(213,56%,19%,1)] to-[hsla(213,47%,17%,1)] pb-24">
          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(204,26%,93%,1)] m-[-1px] after:h-[4vh] md:after:h-[10vh] z-20 "
              }
            ></div>
          </div>

          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-blue-800 m-[-1px] after:h-[4vh] md:after:h-[14vh] z-10 "
              }
            ></div>
          </div>

          <div className="max-w-6xl px-8 py-16 mx-auto lg:pb-32 xl:px-0">
            <div className="flex flex-col justify-between md:items-center md:flex-row">
              <div className="md:w-2/3 ">
                <h2 className="text-4xl font-bold text-white">
                  Case Studies of Digital Transformation
                </h2>
                <p className="mt-8 text-sm leading-6 text-bluegray md:text-base md:leading-8">
                  KATSANA Fleet Management and Industrial Sensors solutions play
                  an integral part in our customer’s fleet and industrial
                  operations. Learn how peers in your industry take advantage of
                  KATSANA solutions to modernize their operation, making
                  real-time data available and automated day-to-day reports.
                </p>
              </div>
              <div
                className="mt-8 md:text-right md:mt-0"
                data-sal="slide-up"
                data-sal-delay="0"
                data-sal-easing="ease-in"
              >
                <h3 className="text-4xl font-bold text-white">
                  <CountUp
                    start={0}
                    end={11.7}
                    delay={0}
                    duration={3}
                    decimals={1}
                    suffix="+ Billion"
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </h3>
                <span className="text-bluegray">
                  sensor data points collected annually
                </span>
                <h3 className="mt-8 text-4xl font-bold text-white">
                  <CountUp
                    start={0}
                    end={2.3}
                    delay={0}
                    duration={3}
                    decimals={1}
                    suffix="+ Billion km"
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </h3>
                <span className="text-bluegray">
                  vehicle movement data annually
                </span>
                <h3 className="mt-8 text-4xl font-bold text-white">
                  <CountUp
                    start={0}
                    end={3600}
                    delay={0}
                    duration={3}
                    suffix="+"
                  >
                    {({ countUpRef }) => (
                      <div>
                        <span ref={countUpRef} />
                      </div>
                    )}
                  </CountUp>
                </h3>
                <span className="text-bluegray">companies across ASEAN</span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-[hsla(204,26%,93%,1)] relative py-4 md:py-12">
          <div className="max-w-6xl pl-8 mx-auto md:px-0">
            {breakpoints.md ? <CustomerAccordion /> : <Customer />}
          </div>

          <div className="absolute bottom-0 left-0 w-full">
            <div
              className={
                Styles.tilt +
                " after:bg-[hsla(203,28%,82%,1)] m-[-1px] after:h-[4vh] md:after:h-[12vh] z-20 "
              }
            ></div>
          </div>
        </div>

        <div className="relative bg-[hsla(203,28%,82%,1)] pb-36 md:pb-48">
          <div className="flex flex-col max-w-5xl px-8 py-8 mx-auto md:items-center md:flex-row md:px-0">
            <div className="flex-1 ">
              <h2 className="text-4xl font-bold text-bluetext">
                GPS Fleet Tracker reviews by real fleet managers
              </h2>
              <p className="max-w-3xl mt-4 text-sm leading-6 md:text-base text-bluetext">
                Fleet managers all over Malaysia love GPS Fleet trackers to
                manage their fleets.
                <br />
                <br />
                The fleets have enjoyed significant savings, improvement in
                security and managed to recover stolen vehicles and assets.
                <br />
                <br />
                Read what they have to say about our quality of service and
                reliability of the KATSANA GPS Fleet and car tracking system.
              </p>
            </div>
            <div className="md:ml-16">
              <div className="flex flex-col mt-8 md:items-center md:mt-0 md:text-center">
                <a
                  href="https://apps.katsana.com/get-quotation"
                  className="self-start px-5 py-2 font-semibold text-white rounded-full md:self-center md:px-12 md:py-3 md:text-base leading-0 bg-primary"
                >
                  Get Free Consultation
                </a>
                <a
                  href="tel:+60377334474"
                  className="mt-4 text-sm text-primary md:flex"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  or call us +603 7733 4474
                  <br />
                  (Kuala Lumpur, GMT +8)
                </a>
              </div>
            </div>
          </div>
          <LazyLoad>
            <ReviewSliderAPAD />
          </LazyLoad>

          <div className="mx-auto max-w-primary">
            <Parallax y={[-40, 5]} tagOuter="figure">
              <StaticImage
                alt="katsana"
                src="../../static/images/apad/apad-permit-renewal.webp"
                objectFit="cover"
                className="relative z-0 md:mt-[-100px] mt-[-100px]  h-[200px] md:h-auto"
              />
            </Parallax>
          </div>
        </div>

        <Cta />
      </Layout>
    </ParallaxProvider>
  );
};

export default CustomerPage;
