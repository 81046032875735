import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Disclosure } from "@headlessui/react";
import { ChevronUpIcon } from "@heroicons/react/solid";
import CustomerSlider from "./customerslider";


const CustomerAccordion = () => {
  const {
    Agriculture,
    Construction,
    GeneralServices,
    Government,
    Logistics,
    PublicTransport,
    PublicWorks,
    Rental,
    SeaPorts,
  } = useStaticQuery(graphql`
    query allCustomersMobile {
      Agriculture: allWpCustomer(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "Agriculture" } } } }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      Construction: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Construction & Property" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      GeneralServices: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "General Services" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      GeneralServices: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "General Services" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      Government: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Government & Local Bodies" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      Logistics: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Logistics & Transportation" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }  
      PublicTransport: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Public Transport" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      PublicWorks: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Public Works & Utilities" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      Rental: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Rental & Leasing" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }
      SeaPorts: allWpCustomer(
        filter: {
          categories: {
            nodes: { elemMatch: { name: { eq: "Sea Ports & Terminals" } } }
          }
        }
      ) {
        nodes {
          title
          content
          customer {
            url
            logo {
              sourceUrl
            }
          }
          categories {
            nodes {
              name
            }
          }
        }
      }                                                               
    }
  `);

  return (
    <div className="md:pl-8">
      {/* Agriculture */}
      <Disclosure as="div" defaultOpen={true}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Agriculture</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={Agriculture} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Construction */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Construction & Property</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={Construction} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* General Services */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>General Services</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={GeneralServices} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Government */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Government & Local Bodies</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={Government} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Logistic */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Logistics & Transportation</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={Logistics} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Sea Ports */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Sea Ports</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={SeaPorts} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Public Transport */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Public Transport</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={PublicTransport} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Public Works */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Public Works & Utilities</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={PublicWorks} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
      {/* Rental */}
      <Disclosure as="div" defaultOpen={false}>
        {({ open }) => (
          <div>
            <Disclosure.Button
              className={`${
                open ? "text-white border-b-0" : ""
              } flex justify-between w-full h-14 items-center text-sm font-medium text-left text-blue-800 focus:outline-none`}
            >
              <span>Rental & Leasing</span>
              <ChevronUpIcon
                className={`${
                  open ? "transform rotate-180 text-white" : ""
                } w-5 h-5 text-blue-800 mr-5`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="py-6 text-sm">
              <CustomerSlider datas={Rental} />
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};

export default CustomerAccordion;
